
const pontosControle = [
  {cod: "", label: ""},
  {cod: "1.1.1", label: "Prestação de contas anual – execução orçamentária"},
  {cod: "1.1.2", label: "Despesa – realização sem prévio empenho"},
  {cod: "1.2.1", label: "Registro por competência - despesas previdenciárias patronais "},
  {cod: "1.2.2", label: "Pagamento das obrigações previdenciárias - parte patronal"},
  {cod: "1.2.3", label: "Registro por competência – multas e juros por atraso de pagamento"},
  {cod: "1.2.4", label: "Retenção/Repasse das contribuições previdenciárias- parte servidor"},
  {cod: "1.2.5", label: "Parcelamento de débitos previdenciários"},
  {cod: "1.2.6", label: "Registro por competência -  Receitas de Contribuições "},
  {cod: "1.2.7", label: "Registro por competência – multas e juros por atraso no pagamento"},
  {cod: "1.2.8", label: "Medidas de Cobrança- Créditos Previdenciários a Receber e Parcelamentos a Receber"},
  {cod: "1.2.9", label: "Despesa Administrativa do RPPS"},
  {cod: "1.2.10", label: "Disponibilidades financeiras - contas específicas"},
  {cod: "1.2.11", label: "Disponibilidades financeiras – Limites e condições de proteção e prudência nas aplicações"},
  {cod: "1.2.12", label: "Disponibilidades financeiras – vedações"},
  {cod: "1.2.13", label: "Equilíbrio financeiro do RPPS – Ingressos e desembolsos"},
  {cod: "1.2.14", label: "Equilíbrio financeiro e atuarial – Plano de equacionamento"},
  {cod: "1.2.15", label: "Escrituração Contábil –Provisões matemáticas previdenciárias"},
  {cod: "1.2.16", label: "Escrituração Contábil"},
  {cod: "1.2.17", label: "Conciliação de contas – Registro de créditos a receber"},
  {cod: "1.2.18", label: "Conciliação de contas  – Controle da receita de contribuições previdenciárias"},
  {cod: "1.2.19", label: "Conciliação de contas – Registro de provisões matemáticas previdenciárias"},
  {cod: "1.3.1", label: "Bens em estoque, móveis, imóveis e intangíveis – registro contábil compatibilidade com inventário."},
  {cod: "1.3.2", label: "Bens móveis, imóveis e intangíveis – Registro e controle"},
  {cod: "1.3.3", label: "Disponibilidades financeiras – depósito e aplicação em instituições financeiras Oficiais"},
  {cod: "1.3.4", label: "Disponibilidades financeiras – depósito e aplicação  confirmação externa"},
  {cod: "1.3.5", label: "Dívida ativa e demais créditos tributários – conciliação do demonstrativo do sistema tributário com as demonstrações contábeis"},
  {cod: "1.3.6", label: "Dívida ativa e demais créditos tributários – cobrança regular"},
  {cod: "1.3.7", label: "Obrigações contraídas no último ano de mandato"},
  {cod: "1.3.8", label: "Bens em estoque, Imobilizado e intangível – registro contábil  existência Física"},
  {cod: "1.4.1", label: "Educação – aplicação mínima"},
  {cod: "1.4.2", label: "Educação – remuneração dos profissionais do magistério"},
  {cod: "1.4.3", label: "Educação - Pertinência"},
  {cod: "1.4.4", label: "Saúde – aplicação mínima"},
  {cod: "1.4.5", label: "Saúde – pertinência"},
  {cod: "1.4.6", label: "Despesas com pessoal – abrangência."},
  {cod: "1.4.7", label: "Despesas com pessoal – limite"},
  {cod: "1.4.8", label: "Despesas com pessoal – descumprimento de limites – nulidade do ato"},
  {cod: "1.4.9", label: "Despesas com pessoal – aumento despesas nos últimos 180 dias do fim de mandato – nulidade do ato"},
  {cod: "1.4.10", label: "Despesas com pessoal – limite prudencial – vedações"},
  {cod: "1.4.11", label: "Despesas com pessoal – extrapolação do limite – providências / medidas de contenção"},
  {cod: "1.4.12", label: "Despesas com pessoal – expansão de despesas – existência de dotação orçamentária – autorização na LDO"},
  {cod: "1.4.13", label: "Poder Legislativo Municipal – despesa com folha de pagamento"},
  {cod: "1.4.14", label: "Transferências para o Poder Legislativo Municipal"},
  {cod: "1.4.15", label: "Dívida pública – extrapolação de limite no decorrer da execução orçamentária – redução do valor excedente"},
  {cod: "1.4.16", label: "Operação de crédito por antecipação de receita orçamentária – limite"},
  {cod: "1.4.17", label: "Despesas com pessoal – subsídio dos vereadores – fixação "},
  {cod: "1.4.18", label: "Despesas com pessoal – subsídio dos vereadores – pagamento"},
  {cod: "1.4.19", label: "Poder Legislativo Municipal –Despesas com pessoal – remuneração vereadores "},
  {cod: "1.4.20", label: "Poder Legislativo Municipal – despesa total cumprimento dos  percentuais definidos no caput do art. 29-A da CRFB/88"},
  {cod: "1.5.1", label: "Documentos integrantes da PCA – compatibilidade com o normativo do TCE"},
  {cod: "1.5.2", label: "Segregação de funções."},
  {cod: "1.6.1.1", label: "Extrato consolidado da folha de pagamento"},
  {cod: "1.6.1.2", label: "Liquidação da folha de pagamento"},
  {cod: "2.1.1", label: "LDO – compatibilidade com Plano Plurianual."},
  {cod: "2.1.2", label: "LDO – limitação de empenho."},
  {cod: "2.1.3", label: "LDO – controle de custos e avaliação de resultados de programas."},
  {cod: "2.1.4", label: "LDO – condições para transferências de recursos a entidades privadas."},
  {cod: "2.1.5", label: "LDO – Anexo de Metas Fiscais – abrangência"},
  {cod: "2.1.6", label: "LDO – Anexo de Metas Fiscais – conteúdo"},
  {cod: "2.1.7", label: "LDO – Anexo de Riscos Fiscais – abrangência"},
  {cod: "2.1.8", label: "LDO – Anexo de Riscos Fiscais – conteúdo"},
  {cod: "2.1.9", label: "Programação orçamentária – disponibilização de estudos e estimativas de receitas."},
  {cod: "2.1.10", label: "LOA – compatibilidade com a LDO e com o Plano Plurianual."},
  {cod: "2.1.11", label: "LOA – demonstrativo da compatibilidade dos orçamentos com objetivos e metas da LRF"},
  {cod: "2.1.12", label: "Renúncia de Receita – demonstrativo regionalizado dos efeitos da renúncia de receita"},
  {cod: "2.1.13", label: "LOA – Reserva de contingência"},
  {cod: "2.1.14", label: "LOA – previsão de recursos para pagamento de precatórios"},
  {cod: "2.1.15", label: "LOA – vinculação de recursos."},
  {cod: "2.1.16", label: "LOA – programação financeira e cronograma de desembolso."},
  {cod: "2.1.17", label: "Transparência na gestão - realização de audiências públicas"},
  {cod: "2.2.1", label: "Anexo de Metas Fiscais – cumprimento de metas fiscais."},
  {cod: "2.2.2", label: "Receita: Instituição, previsão e efetiva arrecadação dos tributos."},
  {cod: "2.2.3", label: "Renúncia de receitas – estimativa de impacto orçamentário-financeiro."},
  {cod: "2.2.4", label: "Renúncia de receitas – eficácia da concessão ou ampliação do incentivo."},
  {cod: "2.2.5", label: "Renúncia de receitas – legislação específica"},
  {cod: "2.2.6", label: "Renúncia de receitas – resultados"},
  {cod: "2.2.7", label: "Renúncia de receitas – avaliação dos projetos"},
  {cod: "2.2.8", label: "Despesa pública – criação, expansão ou aperfeiçoamento de ação governamental que acarrete aumento da despesa – estimativa de impacto orçamentário-financeiro."},
  {cod: "2.2.9", label: "Despesa pública – criação, expansão ou aperfeiçoamento de ação governamental que acarrete aumento da despesa – afetação das metas fiscais."},
  {cod: "2.2.10", label: "Execução de programas e projetos"},
  {cod: "2.2.11", label: "Execução de despesas – créditos orçamentários"},
  {cod: "2.2.12", label: "Execução de despesas – vinculação"},
  {cod: "2.2.13", label: "Créditos adicionais – autorização legislativa para abertura"},
  {cod: "2.2.14", label: "Créditos adicionais – decreto executivo"},
  {cod: "2.2.15", label: "Créditos orçamentários – transposição, remanejamento e transferências"},
  {cod: "2.2.16", label: "Autorização orçamentária para cobertura de déficit"},
  {cod: "2.2.17", label: "Autorização legislativa para instituição de fundos de qualquer natureza"},
  {cod: "2.2.18", label: "Realização de investimentos plurianuais"},
  {cod: "2.2.19", label: "Créditos extraordinários – abertura"},
  {cod: "2.2.20", label: "Execução da programação financeira de desembolso."},
  {cod: "2.2.21", label: "Transparência na gestão – instrumentos de planejamento e demonstrativos fiscais"},
  {cod: "2.2.22", label: "Transparência na gestão – execução orçamentária"},
  {cod: "2.2.23", label: "Transparência na gestão – prestação de contas"},
  {cod: "2.2.24", label: "Escrituração e consolidação das contas públicas"},
  {cod: "2.2.25", label: "Transparência na gestão - Conteúdo do RREO"},
  {cod: "2.2.26", label: "Limitação para custeio de despesas"},
  {cod: "2.2.27", label: "Concessão de privilégios fiscais para empresas públicas ou sociedades de economia mista."},
  {cod: "2.2.28", label: "Pagamento de passivos – ordem cronológica das exigibilidades"},
  {cod: "2.2.29", label: "Déficit orçamentário – medidas de contenção"},
  {cod: "2.2.30", label: "Despesa – realização de despesas – irregularidades"},
  {cod: "2.2.31", label: "Despesa – liquidação"},
  {cod: "2.2.32", label: "Pagamento de despesas sem regular liquidação"},
  {cod: "2.2.33", label: "Despesa – desvio de finalidade"},
  {cod: "2.2.34", label: "Despesa – auxílios, contribuições e subvenções."},
  {cod: "2.2.35", label: "Despesa – subvenção social."},
  {cod: "2.2.3.6 ", label: "Receita - Identificação de possiblidade de instauração de tomada de contas para recuperação dos créditos prescritos, mas não cobrados"},
  {cod: "2.2.3.7", label: "Renúncia de receitas - autorização da Lei de Diretrizes Orçamentárias"},
  {cod: "2.2.3.8", label: "Renúncia de Receitas - Medidas de compensação para manutenção do equilíbrio fiscal"},
  {cod: "2.2.3.9", label: "Receita - Cadastro mobiliário municipal"},
  {cod: "2.2.3.10", label: "Receita - Previsão Legislativa da Planta Genérica de Valores - base de cálculo do IPTU"},
  {cod: "2.2.3.11", label: "Receita - Compatibilidade do valor venal de IPTU em frente do valor de mercado dos imóveis"},
  {cod: "2.2.3.12", label: "Receita - Manutenção e atualização do cadastro imobiliário"},
  {cod: "2.2.3.13", label: "Receita - Lançamento do ITBI por agente competente"},
  {cod: "2.2.3.14", label: "Receita - Aferição da base de cálculo do ITBI por critérios técnicos"},
  {cod: "2.2.3.15", label: "Receita - Instituição da taxa de resíduos sólidos"},
  {cod: "2.2.3.16", label: "Receita - Cobrança da contribuição sobre o custeio do serviço de iluminação pública"},
  {cod: "2.2.3.17", label: "Receita - Legalidade sobre o lançamento da COSIP"},
  {cod: "2.2.3.18", label: "Receita - Verificação de enriquecimento sem causa da concessionária de energia elétrica sobre o município"},
  {cod: "2.2.3.19", label: "Receita - Registro em contas específicas da COSIP"},
  {cod: "2.2.3.20", label: "Receita - Inscrição em dívida ativa"},
  {cod: "2.2.3.21", label: "Receita - Regularidade das Certidões de Dívida Ativa"},
  {cod: "2.2.3.22", label: "Receita - Controles de certeza e liquidez pela administração"},
  {cod: "2.2.3.23", label: "Receita - Procedimentos de cobrança administrativa dos créditos da dívida ativa"},
  {cod: "2.2.3.24", label: "Receita - Procedimento de protesto extrajudicial dos créditos da dívida ativa"},
  {cod: "2.2.3.25", label: "Receita - Rotinas de cobrança administrativa dos créditos da dívida ativa"},
  {cod: "2.2.3.26", label: "Receita -Verificação do valor mínimo e ajuizamento de execução fiscal para cobrança da dívida ativa."},
  {cod: "2.2.3.27", label: "Receita - Verificação da prescrição dos créditos tributários e dos procedimentos de cobrança"},
  {cod: "2.2.3.28", label: "Consolidação - Contas INTRA"},
  {cod: "2.2.3.29", label: "Transparência na gestão - divulgação dos demonstrativos fiscais (RGF)"},
  {cod: "2.2.3.30", label: "Transparência na gestão - Conteúdo do RGF"},
  {cod: "2.3.1", label: "Passivos contingentes – reconhecimento de precatórios judiciais"},
  {cod: "2.3.2", label: "Dívida pública – precatórios – pagamento"},
  {cod: "2.3.3", label: "Evidenciação de resultados – consolidação"},
  {cod: "2.3.4", label: "Dívida ativa e demais créditos tributários – cancelamento"},
  {cod: "2.3.5", label: "Cancelamento de passivos"},
  {cod: "2.4.1", label: "Transferências voluntárias – exigências"},
  {cod: "2.4.2", label: "Dívida pública – precatórios – integração na dívida consolidada"},
  {cod: "2.4.3", label: "Dívida pública – originalmente superior ao limite – redução do valor excedente "},
  {cod: "2.4.4", label: "Dívida pública – evidenciação no RGF"},
  {cod: "2.4.5", label: "Dívida pública – extrapolação de limite no decorrer da execução orçamentária – redução do valor excedente"},
  {cod: "2.4.6", label: "Operação de crédito – instituição financeira controlada"},
  {cod: "2.4.7", label: "Operação de crédito – instituição financeira controlada"},
  {cod: "2.4.8", label: "Operação de crédito – vedações"},
  {cod: "2.4.9", label: "Operação de crédito – vedações"},
  {cod: "2.4.10", label: "Operação de crédito – Regra de Ouro"},
  {cod: "2.4.11", label: "Operação de crédito – limite global"},
  {cod: "2.4.12", label: "Operação de crédito – limite para amortizações, juros e mais encargos"},
  {cod: "2.4.13", label: "Operação de crédito – atendimento às condições  para contratação"},
  {cod: "2.4.14", label: "Operação de crédito – concessão de garantias e contragarantias"},
  {cod: "2.4.15", label: "Garantias e contragarantias – limite"},
  {cod: "2.4.16", label: "Operação de crédito – cláusulas contratuais vedadas "},
  {cod: "2.4.17", label: "Operação de crédito por antecipação de receita orçamentária – exigências para contratação"},
  {cod: "2.4.18", label: "Operação de crédito por antecipação de receita orçamentária – vedações"},
  {cod: "2.5.1", label: "Retenção de impostos, contribuições sociais e previdenciárias."},
  {cod: "2.5.2", label: "Base de cálculo de contribuições - RPPS"},
  {cod: "2.5.3", label: "Alíquota de contribuição – Fixação"},
  {cod: "2.5.4", label: "Alíquota de contribuição – Retenção e recolhimento"},
  {cod: "2.5.5", label: "Guia de recolhimento de contribuições previdenciárias"},
  {cod: "2.5.6", label: "Contribuições previdenciárias dos servidores cedidos"},
  {cod: "2.5.7", label: "Servidores cedidos"},
  {cod: "2.5.8", label: "Controle informatizado e individualizado das contribuições dos servidores"},
  {cod: "2.5.9", label: "Disponibilização do registro individualizado ao segurado"},
  {cod: "2.5.10", label: "Parcelamento de débitos previdenciários – Autorização Legal"},
  {cod: "2.5.11", label: "Compensação Previdenciária"},
  {cod: "2.5.12", label: "Orçamento – Fontes de recursos vinculadas"},
  {cod: "2.5.13", label: "Orçamento – Fontes de recursos não vinculadas"},
  {cod: "2.5.14", label: "Transparência"},
  {cod: "2.5.15", label: "Atuação dos conselhos de previdência"},
  {cod: "2.5.16", label: "Obrigações do MPS"},
  {cod: "2.5.17", label: "Avaliação atuarial - Inicial"},
  {cod: "2.5.18", label: "Avaliação atuarial – reavaliação anual"},
  {cod: "2.5.19", label: "Cálculo atuarial – data base"},
  {cod: "2.5.20", label: "Plano de Amortização – instituição por lei"},
  {cod: "2.5.21", label: "Plano de Amortização – estudo de viabilidade"},
  {cod: "2.5.22", label: "Contabilização da amortização do déficit atuarial"},
  {cod: "2.5.23", label: "Segregação de planos - estabelecimento por lei"},
  {cod: "2.5.24", label: "Transferência de recursos entre Fundos Previdenciário e Financeiro"},
  {cod: "2.5.25", label: "Recadastramento dos inativos e pensionistas"},
  {cod: "2.5.26", label: "Censo Atuarial"},
  {cod: "2.5.27", label: "Hipóteses Atuariais - Definição"},
  {cod: "2.5.28", label: "Hipóteses Atuariais – Eleição conjunta"},
  {cod: "2.5.29", label: "Meta atuarial - definição"},
  {cod: "2.5.30", label: "Meta atuarial - cumprimento"},
  {cod: "2.5.31", label: "Comitê de investimentos - instituição"},
  {cod: "2.5.32", label: "Comitê de investimentos - Reuniões"},
  {cod: "2.5.33", label: "Comitê de investimentos – Certificados dos membros"},
  {cod: "2.5.34", label: "Politica de Investimento"},
  {cod: "2.5.35", label: "Aplicação dos recursos"},
  {cod: "2.5.36", label: "Utilização do Formulário (APR)"},
  {cod: "2.5.37", label: "Registro de Admissões"},
  {cod: "2.5.38", label: "Registro de Aposentadorias, reserva remunerada e reforma"},
  {cod: "2.5.39", label: "Registro de Pensões"},
  {cod: "2.5.40", label: "Concessão e pagamento indevidos de aposentadoria por invalidez"},
  {cod: "2.5.41", label: "Pagamento de benefícios não previdenciários"},
  {cod: "2.5.42", label: "Pagamento Indevido de Benefícios por morte do beneficiário"},
  {cod: "2.5.43", label: "Pagamento indevido – beneficiários falecidos"},
  {cod: "2.5.44", label: "Ação de repetição de indébito em relação a pagamento indevido de benefício"},
  {cod: "2.5.45", label: "Despesa Administrativa – fixação em lei"},
  {cod: "2.5.46", label: "Despesa administrative – cumprimento do limite"},
  {cod: "2.6.1", label: "Pessoal – função de confiança e cargos em comissão"},
  {cod: "2.6.2", label: "Pessoal – função de confiança e cargos em comissão"},
  {cod: "2.6.3", label: "Pessoal – contratação por tempo determinado"},
  {cod: "2.6.4", label: "Pessoal – teto"},
  {cod: "2.6.5", label: "Realização de despesas sem previsão em lei específica."},
  {cod: "2.6.6", label: "Dispensa e inexigibilidade de licitação."},
  {cod: "2.7.1.1", label: "Resolutividade das inconsistências indicativas informadas na remessa de dados da folha de pagamento (PCF)"},
  {cod: "2.5.1.2", label: "Resolutividade dos pontos de controle existentes na remessa de dados da folha de pagamento (PCF)"},
  {cod: "2.7.2.1", label: "Registro de Admissões – envio ao TCE"},
  {cod: "2.7.2.2", label: "Registro de Admissões – apreciação do controle interno"},
  {cod: "2.7.2.3", label: "Registro de Aposentadorias, reserva remunerada e reforma – envio ao TCE"},
  {cod: "2.7.2.4", label: "Registro de Aposentadorias, reserva remunerada e reforma – apreciação do controle interno"},
  {cod: "2.7.2.5", label: "Registro de Pensões – envio ao TCE"},
  {cod: "2.7.2.6", label: "Registro de Pensões – apreciação do controle interno"},
]

export default pontosControle
